import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { Container } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";

const PostList = ({ data }) => {
  if (!data) return null;

  const { page, allPrismicPost } = data;

  // console.log(data);

  return (
    <>
      <Seo page={page} />
      <Layout>
        <div
          className="container-fluid text-center py-5"
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <h2>{page.data.title}</h2>
        </div>
        <Container className="px-3">
          <div className="row">
            {allPrismicPost.nodes.map((post) => {
              return (
                <div
                  style={{ paddingLeft: "2rem" }}
                  className="col-12 col-lg-6 border-bottom custom-post"
                >
                  <Anchor
                    href={post.url}
                    className="text-muted text-decoration-none"
                  >
                    <h2 style={{ borderBottom: "2px solid #2591C0 " }}>
                      {post.data.title}
                    </h2>
                    <GatsbyImage
                      image={post.data.image.gatsbyImageData}
                      alt={post.data.image.alt}
                      style={{ width: "auto", height: "200px" }}
                    />
                  </Anchor>

                  <PrismicRichText field={post.data.summary.richText} />
                  <p>{post.data.date}</p>
                </div>
              );
            })}
          </div>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query postListQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicPostList(lang: { eq: $lang }, id: { eq: $id }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      id
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title
      }
    }
    allPrismicPost {
      nodes {
        data {
          title
          summary {
            richText
          }
          date
          image {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
        url
      }
    }
  }
`;

export default PostList;
